import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  ViewModuleName,
  ViewChoice,
  ViewImgTop,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_22.mp3';
import Background from '../images/landscape-2.jpg';
import Borne2 from '../images/Bornes-2.png';
import Part2Videos from '../components/videos/part-2-videos.fr';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;

  > * {
    display: flex;
  }
`;

const Part2_Videos = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      pathname="/fr/part-2-videos"
      title="Découvre l’application FinDiETou et les vidéos du module “Gérer mon argent en bon père de famille”"
      description="Bienvenue dans la Partie 2 qui t’apprendra à bien Gérer tes revenus en bon père de famille. Elle te donnera des conseils pour comprendre l’importance de l’épargne ainsi que les moyens à mettre en œuvre pour te constituer une épargne pour financer tes projets et les besoins de ta famille. Bonne formation !"
      quote="Bienvenue dans la Partie 2 qui t’apprendra à bien Gérer tes revenus en bon père de famille. Elle te donnera des conseils pour comprendre l’importance de l’épargne ainsi que les moyens à mettre en œuvre pour te constituer une épargne pour financer tes projets et les besoins de ta famille. Bonne formation !"
      hashtag="#FinDiETou"
      banner="https://i.postimg.cc/tgwm4T7R/Module-2.jpg"
    />

    <ViewArticle>
      <Part2Videos />
      <ViewModuleName>
        <ViewChoice>
          <ViewImgTop src={Borne2} />
          Gérer mon argent en bon père de famille
        </ViewChoice>
      </ViewModuleName>
    </ViewArticle>
    <Player sound={Sound} />

    <Link to="/fr/part-2-astuces">
      <ViewButtonPrevious color="warning">
        <i className="fa fa-chevron-left fa" />
      </ViewButtonPrevious>
    </Link>
    <Link to="/fr/part-2-evaluation">
      <ViewButtonNext color="warning">
        <i className="fa fa-chevron-right" />
      </ViewButtonNext>
    </Link>
    <ShareButtons />
    <Breadcrumbs active={5} />
  </Layout>
);

export default Part2_Videos;
